import React, { useEffect, useState } from 'react';
import Cloud from './Cloud';


function Banner({person, datas}) {
	const [colorText, setColorText] = useState('#000000');
	const baseURL = 'https://www.coffineau.fr/api/public';

	useEffect(() => {
		let start = datas.color.slice(1,-5);
		if(start.match(/^([0-7])$/)) {
			setColorText('#ffffff');
		}
	}, [datas]);

	return (
		<>
			<div className="cv-banner cv-container">
				<h1 className="cv-title" style={{color:colorText}}>{person.firstName + ' ' + person.lastName.toUpperCase()}</h1>
				<div style={{ height: 300, width: 600 }} className='display-cloud'>
					<Cloud skills={datas.skills} />
				</div>
				<div><img src={`${baseURL}/${datas.image.path}`} alt="Olivia" className="cv-logo" /></div>
			</div>
			<div className='cv-title text-center' style={{color:colorText}}>{datas.title}<br /><span className='cv-subtitle'>{datas.subtitle}</span></div>
		</>
	);
}

export default Banner;
