import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm.jsx";

const Contact = ({person, pdfColor}) => {

  return (
    <div className="cv-container">
      <h2 style={{color: pdfColor}}>Comment me joindre</h2>
      {/* <div>
        <div style={{color: pdfColor}} className="text-xl font-bold mb-3">
          {person.firstName} <span>{person.lastName.toUpperCase()}</span>
        </div>
      
      </div> */}
      <ContactForm person={person}/>
			  <div className="mb-3">
				<h2 style={{color: pdfColor}}>Directement par mail</h2>
          <a href={`mailto:${person.mail}`}>
            <FontAwesomeIcon icon={faEnvelope} /> {person.mail}
          </a>
        </div>
        <iframe
          title="Bourg-La-Reine"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10516.384609189008!2d2.3079792891649737!3d48.780054568476444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e670d436538651%3A0x2ad90b5507853231!2sBourg-La-Reine!5e0!3m2!1sfr!2sfr!4v1676393091712!5m2!1sfr!2sfr"
          width="300"
          height="200"
          style={{border: "0"}}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
    </div>
  );
};

export default Contact;
