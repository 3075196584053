import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const Job = ({ jobs, pdfColor }) => {
	const [describe, setDescribe] = useState([])
	const styleColor = {
		color: pdfColor
	}

	useEffect(() => {
		if (jobs.length > 0) {
			let descr = [];
			jobs.map((job) => (
				descr.push({ 'id': job.id, 'descr': job.description.split('\n') })
			));
			setDescribe(descr)
		}
	}, [jobs])
	
	return (
		jobs &&
		jobs.length > 0 && (
			<>
				<div className="cv-container">
					<h2 style={{ color: pdfColor }}>Mes expériences</h2>
					{jobs.sort((a, b) => b.startDate > a.startDate).map((job) => (
						<div key={job.id} className='mb-4'>
							<div className='mb-4'>
								<div >
									<span className="font-bold text-lg subtitle" style={styleColor}>
										{job.designation}
									</span> - <span className="font-bold" style={styleColor}>{job.society} </span>(<span className='text-xs'>{job.city}</span>)
								</div>
								<div>
									<span className="font-bold text-sm" style={styleColor}>
										{dayjs(job.startDate).format('MM-YYYY') +
											' à ' +
											dayjs(job.endDate).format('MM-YYYY')}
									</span>

								</div>
								<div>
									<span className="font-bold" style={styleColor}>Description : </span>
									{Array.isArray(describe) ? (
										describe.map((desc) => (
											desc.id === job.id && (
												desc.descr.map((d, k) => (
													<ul key={k} className="ml-3">
														<li>{d}</li>
													</ul>
												))
											)
										))
									) : (
										<div className="">{job.description}</div>
									)}

								</div>
							</div>
							<hr />
						</div>
					))}
				</div>
			</>
		)
	);
};

export default Job;
