import './App.css';
import Nav from './components/Nav';
import Banner from './components/Banner';
import React, { useEffect, useState } from 'react';
import { getDatasForSite } from './services/Axios';

function App() {
  const [person, setPerson] = useState(null);
	const [datasForSite, setDatasForSite] = useState([]);
	const bgColor = { backgroundColor: datasForSite?.color}

	 useEffect(() => {
		getDatasForSite().then((data) => {
			setDatasForSite(data.data);
			setPerson(data.data.person);
		});
	}, []);

  return (
		person && (
			<div>
				<header className="App-header" style={bgColor}>
					<Banner person={person} datas={datasForSite} />
				</header>
				<div>
					<Nav person={person} datas={datasForSite}/>
				</div>	
				<footer className="App-footer" style={bgColor}>
					<span className='mb-4'>Made with <span className="text-red-400">&#9825;</span> by Olivia Coffineau &copy;2024</span>
				</footer>
			</div>
		)
	);
}

export default App;
