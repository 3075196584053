import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Formation from '../contents/Formation';
import Job from '../contents/Job';
import Home from '../Home';
import Contact from '../contents/Contact';
import Skill from '../contents/Skill';

function Nav({person, datas}) {
	const [colorText, setColorText] = useState('#000000');
	const style = {
		backgroundColor: datas.color,
		color: colorText,
	}

	useEffect(() => {
		let start = datas.color.slice(1,-5);
		if(start.match(/^([0-7])$/)) {
			setColorText('#ffffff')
		}
	}, [datas]);

  return (
		<Tabs>
		<div style={style}>
			<TabList className='cv-container-nav' >
				<Tab>Accueil</Tab>
				<Tab>Formations</Tab>
				<Tab>Expériences professionnelles</Tab>
				<Tab>Compétences</Tab>
				<Tab>Contact</Tab>
			</TabList>
	</div>
			<TabPanel>
				<Home pdfColor={datas.color} description={datas.description} idPdf={datas.id}/>
			</TabPanel>
			<TabPanel>
				<Formation formations={datas.formations} pdfColor={datas.color}/>
			</TabPanel>
			<TabPanel>
				<Job jobs={datas.jobs} pdfColor={datas.color} />
			</TabPanel>
			<TabPanel>
				<Skill skill={datas.skills} pdfColor={datas.color} />
			</TabPanel>
			<TabPanel>
				<Contact person={person} pdfColor={datas.color}/>
			</TabPanel>
		</Tabs>
	);
};

export default Nav;
