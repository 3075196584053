import axios from 'axios';

const baseURL = 'https://www.coffineau.fr/api/';

export const getPerson = async () => {
	return await axios.get(baseURL +'person');
};

export const getOnePerson = (id) => {
	return axios.get(baseURL + 'person/' + id);
};

export const getCategories = () => {
	return axios.get(baseURL + 'categories');
};

export const getJob = (id) => {
	return axios.get(baseURL + 'jobs/' + id);
};

export const getDatasForSite = async () => {
	return await axios.get(baseURL + 'site')
}

export const getAllPdf = async () => {
	return await axios.get(baseURL + 'pdf')
}

export const createContact = (data, personId, customEvent) => {
  return axios.post(baseURL + 'contacts/'+ personId, data)
    .then(response => {
      // Si tout se passe bien, déclenchez l'événement personnalisé
      console.log(customEvent)
      if (customEvent) {
        document.dispatchEvent(new Event(customEvent));
      }
      console.log(1000, response)
      return response;
    })
    .catch(error => {
      // Gérez les erreurs si nécessaire
      console.error(error);
      throw error;
    });
};
