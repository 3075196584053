import React from 'react';
import ReactWordcloud from 'react-wordcloud';

function Cloud({ skills }) {
  const callbacks = {
		getWordColor: (word) => (word.color ? word.color : 'black')
	};
  const options = {
		rotations: 4,
		rotationAngles: [-45, 45],
		scale: 'sqrt',
		spiral: 'archimedean',
		fontWeight: 'bold',
    fontFamily: "titlePolice",
		fontSizes: [18, 36],
		padding: 10,
		enableTooltip:false,
	};
  const size = [500, 250];

  let words = [];

 skills.length > 0 && (skills?.map((skill) => {
  let word = {};
    word['text'] = skill.name;
    word['value'] = skill.category ? skill.category?.id : 0;
    word['category'] = skill.category?.name;
    word['color'] = skill.category?.color;
    words.push(word);
    return words;
  }));

  return (
    <ReactWordcloud
      callbacks={callbacks}
      options={options}
      size={size}
      words={words}
    />
  );
}
export default Cloud;
