import React, {useState, useRef} from "react";
import {useForm} from "react-hook-form";
import {createContact} from "../services/Axios";
import emailjs from 'emailjs-com';

const ContactForm = ({person}) => {
  const {register, handleSubmit, formState: {errors}} = useForm();
  const [contact, setContact] = useState();
  const [error, setError] = useState();
  const formRef = useRef();

  const onSubmit = (data) => {
    // Envoi des données avec EmailJS
    emailjs.sendForm('service_raxekil', 'template_th9p9wb', formRef.current, 'fY4ltzfNni5IxcI8x')
      .then((result) => {
          console.log('EmailJS Result:', result.text);
      }, (error) => {
          console.error('EmailJS Error:', error.text);
      });
    
    // Envoi des données via Axios
    createContact(data, person.id)
      .then((response) => {
        console.log('RESPONSE DATA',response.data)
        setContact(response.data);
      })
      .catch((error) => {
        console.error('Axios Error:', error);
        setError("Une erreur s'est produite lors de l'envoi du formulaire.");
      });
  };

  return (
    contact ? (
        <div>Merci {contact.firstName} pour votre message, je reviens vers vous très vite.</div>
      ) : (
    <div>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label>Prénom</label>
          <input
            type="text"
            className="form-input"
            {...register("firstName", { required: true })}
          />
          {errors.firstName && <span>This field is required</span>}
        </div>
        <div className="field">
          <label>Nom</label>
          <input
            type="text"
            className="form-input"
            {...register("lastName", { required: true })}
          />
          {errors.lastName && <span>This field is required</span>}
        </div>
        <div className="field">
          <label>Email</label>
          <input
            type="email"
            className="form-input"
            {...register("email", { required: true })}
          />
          {errors.email && <span>This field is required</span>}
        </div>
        <div className="field">
          <label>Société</label>
          <input
            type="text"
            className="form-input"
            {...register("society")}
          />
        </div>
        <div className="field">
          <label>Message</label>
          <textarea
            rows="5"
            className="form-input"
            {...register("message", { required: true })}
          />
          {errors.message && <span>This field is required</span>}
        </div>

        <button className="btn-outline-primary" type="submit">
          Envoyer
        </button>
      </form>
      
      {error && <p>{error}</p>}
    </div>
    )
  );
};

export default ContactForm;
