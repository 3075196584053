import React, { useEffect, useState } from 'react';

const Skill = ({ skill, pdfColor }) => {
	const [categories, setCategories] = useState([]);
	const styleColor = {
		color: pdfColor
	};

	useEffect(() => {
		let cats = skill.map(s => s.category)
		let json_array = cats.map(JSON.stringify);
		let set = new Set(json_array);
		let uniqueCats = Array.from(set);
		uniqueCats = uniqueCats.map(JSON.parse)

		setCategories(uniqueCats)

	}, [skill])

	return (
		<>
			<div className="cv-skill cv-container">
				<div className="mt-4">
					<h2 style={styleColor}>Mes compétences</h2>
					<div className="">
						{categories.length > 0 &&
							categories
								.sort((a, b) => a?.name > b?.name)
								.map((category, ke) => (
									<div key={ke} className='grid grid-cols-12 mb-4'>
										<div className="font-bold col-span-5 subtitle" style={styleColor}>
											{category?.name}
										</div>
										<div className='col-span-7 ml-3'>
											{skill.length > 0 &&
												skill
													.sort((a, b) => a.name > b.name)
													.sort((a, b) => a?.category?.name > b?.category?.name)
													.map(
														(skil, k) =>
															skil?.category?.id === category.id && (
																<div key={k}>
																	{skil?.name}
																	<span className='text-xs'>{skil.comment ? ' : ' + skil.comment : ''}</span>
																</div>
															)
													)}
										</div>
									</div>
								))}
					</div>
				</div>
			</div>
		</>
	);
};

export default Skill;
