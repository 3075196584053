import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setIsVisible(false);
    };

    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'declined');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div style={styles.banner}>
            <p>Ce site Web utilise des cookies pour vous garantir la meilleure expérience sur notre site Web.</p>
            <div style={styles.buttonContainer}>
                <button style={styles.button} onClick={handleAccept}>Accepter</button>
                <button style={styles.button} onClick={handleDecline}>Refuser</button>
            </div>
        </div>
    );
};

const styles = {
    banner: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#000',
        color: '#fff',
        padding: '20px',
        textAlign: 'center',
        zIndex: 1000,
    },
    buttonContainer: {
        marginTop: '10px',
    },
    button: {
        backgroundColor: '#fff',
        color: '#000',
        border: 'none',
        padding: '10px 20px',
        margin: '0 10px',
        cursor: 'pointer',
    },
};

export default CookieConsent;