import React, { useEffect, useState } from 'react';

const Home = ({ pdfColor, description, idPdf }) => {
	const [describe, setDescribe] = useState([]);
	const url = " https://www.coffineau.fr/api/pdf/generate/" + idPdf;

	useEffect(() => {
		if (description) {
			setDescribe(description.split('\n'))
		};
	}, [description])

	return (
		<>
			<div className='cv-description'>
				<div className='mr-4'>
					<h2 style={{ color: pdfColor }}>Quelques mots sur moi...</h2>
					{Array.isArray(describe) ? (
						describe.map((desc, k) => (
							<ul key={k} className="ml-3">
								<li className='mb-2'>{desc}</li>
							</ul>
						))
					) : (
						{ description }
					)}
				</div>
				<div> <button className="btn-outline-primary ml-4 buttonfont" type="button" >
					<a href={url} target="blank" className="text-xl" style={{ color: pdfColor }}>Générer le Pdf du CV</a>
				</button></div>
			</div>
		</>
	);
};

export default Home;
