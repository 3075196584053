import dayjs from 'dayjs';

const Formation = ({formations, pdfColor}) => {
  return (
		formations &&
		formations.length > 0 && (
			<div className='cv-container'>
			<h2 style={{color:pdfColor}}>Mes formations</h2>
			{formations
				.sort((a, b) => b.startDate > a.startDate)
				.map((formation) => (
					<div key={formation.id} className='mb-3'>
						<div>
							<span className="text-sm font-bold subtitle" style={{color:pdfColor}}>{formation.designation}</span> - 
							<span className="text-sm" style={{color:pdfColor}}>{formation.organism}</span> (<span className="text-xs" style={{color:pdfColor}}>{formation.city}</span>)
						</div>
						<div>
							{dayjs(formation.startDate).format('MM-YYYY') +
							' à ' +
							dayjs(formation.endDate).format('MM-YYYY')}
						</div>
					</div>
				)
			)}
			</div>
		)
	);

};

export default Formation;

